<template>
  <customer-view-template>
    <stepper />
    <div class="buyer-data">
      <form action="" @submit.prevent="formSubmit">
        <div class="form-inline">
          <h3>{{ $t('etapas.comprador.tituloEmailTelefone') }}</h3>
          <dass-input-text
           autofocus
            :required="true"
            :disabled="disabled_fields.includes('cpf') || this.disabledFieldWhileGetData"
            type="tel"
            id="dass-input-cpf"
            v-model="form_data.buyer.cpf"
            v-mask="'###.###.###-##'"
            :label="$t('etapas.comprador.cpf') + ':'"
            @blur="validateCpf"
          />

          <dass-input-text
            :required="true"
            :disabled="disabled_fields.includes('nome') || this.disabledFieldWhileGetData"
            type="text"
            v-model="form_data.buyer.nome"
            :label="$t('etapas.comprador.nome') + ':'"
          />
          <dass-input-text
            :required="true"
            :disabled="disabled_fields.includes('email') || this.disabledFieldWhileGetData"
            type="email"
            v-model="form_data.buyer.email"
            :label="$t('etapas.comprador.email') + ':'"
            @blur="validateEmail"
          />
          <dass-input-text
            :required="true"
            type="tel"
            :disabled="disabled_fields.includes('telefone') || this.disabledFieldWhileGetData"
            v-model="form_data.buyer.telefone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            :label="$t('etapas.comprador.telefone') + ':'"
          />
        </div>
        <div>          
          <h3>{{ $t('etapas.comprador.tituloEntrega') }}</h3>
          <div>
            <dass-input-text
              :required="true"
              type="tel"
              id="dass-input-cep"
              v-model="form_data.address.cep"
              :disabled="this.disabledFieldWhileGetData"
              v-mask="'#####-###'"
              :label="$t('etapas.comprador.cep') + ':'"
              @blur="loadDataCep"
            />
            <dass-input-text
              :required="true"
              type="text"
              :disabled="this.disabledFieldWhileGetData"
              v-model="form_data.address.endereco"
              :label="$t('etapas.comprador.endereco') + ':'"
            />
            <dass-input-text
              :required="true"
              type="text"
              :disabled="this.disabledFieldWhileGetData"
              v-model="form_data.address.numero"
              :label="$t('etapas.comprador.numero') + ':'"
            />
          </div>
          <div>
            <dass-input-text
              :required="true"
              type="text"
              v-model="form_data.address.uf"
              v-mask="'AA'"
              :label="$t('etapas.comprador.uf') + ':'"
              :disabled="this.disabledUfField"
            />
            <dass-input-text
              :required="true"
              type="text"
              :disabled="this.disabledFieldWhileGetData"
              v-model="form_data.address.bairro"
              :label="$t('etapas.comprador.bairro') + ':'"
            />
            <dass-input-text
              :required="true"
              type="text"
              v-model="form_data.address.cidade"
              :label="$t('etapas.comprador.cidade') + ':'"
              :disabled="this.disabledCityField"
            />
          </div>
          <div>
            <dass-input-text
              type="text"
              :disabled="this.disabledFieldWhileGetData"
              v-model="form_data.address.complemento"
              :label="$t('etapas.comprador.complemento') + ':'"
            />
          </div>
        </div>
        <div class="actions">
          <dass-button
            :class="this.isSafari ? `dass-button-tertiary-${this.brandName} button` : 'button'"
            :id="`dass-button-tertiary-close-${this.brandName}`"
            variant="tertiary"
            type="button"
            @click="cancel"
          >
            <dass-icon token="icon-content-clear" size="30"></dass-icon>
            {{ $t('dass.cancelar') }}
          </dass-button>

          <dass-button :disabled="isValidData()" :class="this.isSafari ? `dass-button-primary-${this.brandName} button` : 'button'" variant="primary" type="submit">
            {{ $t('dass.proximo') }}
            <dass-icon
              token="icon-navigation-arrow_forward_ios"
              size="30"
            ></dass-icon>
          </dass-button>
        </div>
      </form>
    </div>
  </customer-view-template>
</template>
<script>
import CustomerViewTemplate from '../components/templates/CustomerView'
import Stepper from '../components/organisms/customer_view_template/Stepper'
import { VMoney } from 'v-money'
import { moneyMask } from '../helpers/globalfilters/defaultMoneyMask'
import { mapGetters, mapActions } from 'vuex'
import { typesPayment } from '../helpers/constants/typesPayment'
import { cpfValidator } from '../helpers/functions/cpfValidator'
import { emailValidator } from '../helpers/functions/emailValidator'
const refund = 'Estorno'

export default {
  name: 'BuyerData',
  props: {
    ecommerce: Boolean,
  },
  components: {
    CustomerViewTemplate,
    Stepper,
  },
  directives: { money: VMoney },
  data: () => ({
    moneyMask,
    isSafari: false,
    brandName: '',
    disabledFieldWhileGetData: false,
    disabled_fields: [],
    disabledUfField: true,
    disabledCityField: true,
    form_data: {
      buyer: {
        cpf: '',
        nome: '',
        telefone: '',
        email: '',
      },
      address: {
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: '',
      },
    },
    MOTIVOS: {
      A_PECA_QUE_RECEBI_NAO_FOI_A_QUE_COMPREI: 19,
      O_PRODUTO_CHEGOU_COM_DEFEITO: 20,
    },
  }),
  computed: {
    ...mapGetters('create_protocol', {
      dados_item: 'getDadosItens',
    }),
    ...mapGetters('session', ['getUsuEmail', 'getUser', 'getUserAddress']),
    ...mapGetters('brand', { marca: 'getBrand' }),    
  },
  methods: {
    ...mapActions('create_protocol', [
      'setCurrentStep',
      'createDados',
      'setPictureList',
      'setItensToAddBankData',      
    ]),
    ...mapActions('toasts', {
      showToast: 'showToast',
    }),
    ...mapActions('addressBook', { getDataCeps: 'getDataCeps' }),
    ...mapActions('session', ['handlerUser']),
    formSubmit: function () {
      
      let origem = 'sac'
      if (
        this.dados_item.hasOwnProperty('ecommerce') ||
        (Array.isArray(this.dados_item) &&
          this.dados_item[0].hasOwnProperty('ecommerce'))
      ) {
        origem = 'ecom'
      }
      let dados = {
        origem,
        marcaCodigo: parseInt(this.marca.id),
        nomeCliente: this.form_data.buyer.nome,
        cpfCliente: this.form_data.buyer.cpf,
        emailCliente: this.form_data.buyer.email,
        telefoneCliente: this.form_data.buyer.telefone,
        cepCliente: this.form_data.address.cep.replace(/[^0-9]+/g, ''),
        enderecoCliente: this.form_data.address.endereco,
        numeroEndrCliente: this.form_data.address.numero,
        bairroCliente: this.form_data.address.bairro,
        cidadeCliente: this.form_data.address.cidade,
        UFCliente: this.form_data.address.uf,
        complementoEndrCliente: this.form_data.address.complemento,
      }
      this.createDados(dados)
      this.next()
    },
    next: async function () {
      let path = '/produtos-adquiridos-fora-da-loja-online/confirmacao'        
      if (this.dados_item.hasOwnProperty('ecommerce') || this.ecommerce || (Array.isArray(this.dados_item) && this.dados_item.some(item => item.hasOwnProperty('ecommerce')))) {
        if (!Array.isArray(this.dados_item) &&
          this.dados_item.motivoCodigo ==
            this.MOTIVOS.A_PECA_QUE_RECEBI_NAO_FOI_A_QUE_COMPREI ||
          this.dados_item.motivoCodigo ==
            this.MOTIVOS.O_PRODUTO_CHEGOU_COM_DEFEITO
        ) {
          path = '/produtos-adquiridos-fora-da-loja-online/fotos'
        } else if(Array.isArray(this.dados_item)) {
          if(this.dados_item.some(item => item.desejoNome.includes(refund) && item.pedTipoPgto == typesPayment.BOLETO)) {             
            const itemWithRefund = this.dados_item.filter((item) => {
              return item.desejoNome.includes(refund)
            })            
            this.setItensToAddBankData(itemWithRefund)
            this.setPictureList([])
            return this.$router.push({
              path: '/produtos-adquiridos-online/banco',
            })            
          }
        }
      }
      if (this.dados_item.hasOwnProperty('reclamacao')) {
        path = '/produtos-adquiridos-fora-da-loja-online/fotos'
      }      
      this.setPictureList([])
      this.$router.push({
        path,
      })
    },
    cancel: function () {
      if (this.ecommerce) {
        return this.$router.push({
          path: '/home',
        })
      }
      this.$router.go(-1)
    },
    fillForm: async function () {     
      this.disabledFieldWhileGetData = true
      const session = await this.handlerUser()
      if (!session.hasOwnProperty('user') || !session.hasOwnProperty('address'))
        return this.disabledFieldWhileGetData = false      

      let user_data = session.user || {}
      let address_data = session.address || {}

      let buyer_data = Object.assign({}, this.form_data.buyer, user_data)
      this.form_data.buyer = buyer_data
      this.form_data.buyer.email = user_data.usu_email || ''
      
      let address = Object.assign({}, this.form_data.address, address_data)
      this.form_data.address = address      
      if(this.form_data.address.cep && !(this.form_data.address.cidade || this.form_data.address.uf)) {
       
        this.loadDataCep()
      }
      this.handlerDisableField()
      this.disabledFieldWhileGetData = false
    },

    handlerDisableField: function () {
      const self = this
      const form_data = this.form_data
     
      Object.entries(form_data.buyer).forEach(([key, value]) => {
        if (value !== '') self.disabled_fields.push(key)
      })
    },
    isValidData() {
      if (!this.validateCep() || !this.validateCpf() || !this.validateEmail()) {
        return true
      }
      else {
        return false
      }
    },
    validateCpf() {
        
      if(!this.form_data.buyer.cpf) return false
      if (this.form_data.buyer.cpf.replace(/[^\d]+/g, '').length != 11)  {
        this.form_data.buyer.cpf = ''
        this.$ShowAlert({
          variant: 'error',
          message: this.$t('dass.invalidCpf'),
          hideDelay: 2000
        })
        return false
      }      
      const isValid = cpfValidator(this.form_data.buyer.cpf) 
      if(!isValid) {
        this.$ShowAlert({
          variant: 'error',
          message: this.$t('dass.invalidCpf'),
          hideDelay: 2000
        })       
      }
      return isValid
      
    },
    validateEmail() {
      const isValid =  emailValidator(this.form_data.buyer.email)
     
      if(!isValid) {
        this.form_data.buyer.email = ''
        this.$ShowAlert({
          variant: 'error',
          message: this.$t('dass.invalidEmail'),
          hideDelay: 2000
        })
        return false
      }      
      return true
    },
    validateCep() {
      return (this.form_data.address.cep.replace(/[^\d]/g, '').length == 8)
    },
    async loadDataCep() {
      const cep = this.form_data.address.cep.replace(/[^\d]/g, '')
      
      if(cep.length != 8) {
        this.$ShowAlert({
          variant: 'error',
          message: this.$t('dass.invalidCep'),
          hideDelay: 2000
        })
        
        this.form_data.address.cep = ''
        this.form_data.address.cidade = ''
        this.form_data.address.uf = ''
        return
      }
      this.disabledFieldWhileGetData = true
      this.disabledCityField = true 
      this.disabledUfField = true
      try {
        const data = await this.getDataCeps(cep) 
        this.disabledFieldWhileGetData = false      
        if(data.length > 0 ) {
          this.form_data.address.cidade = data[0].LOC_NOME
          this.form_data.address.uf = data[0].UF_CODIGO
        } else {
          this.form_data.address.cep = ''
          this.form_data.address.cidade = ''
          this.form_data.address.uf = ''
          this.$ShowAlert({
            variant: 'error',
            message: this.$t('dass.zipCodeNotFound'),
            hideDelay: 2000
          })
        }
      } catch (error) {
        this.disabledFieldWhileGetData = false    
        this.disabledCityField = false
        this.disabledUfField = false            
      }
    }
  },

  created: async function () {
    this.isSafari = /^((?!chrome).)*safari/i.test(navigator.userAgent)
    this.brandName = this.marca.name.replace(/\s/g, '').toLowerCase()   
    this.setCurrentStep(2)
    this.form_data.buyer.email = this.getUsuEmail
    await this.fillForm()    
    if (
      this.dados_item.length &&
      this.dados_item[0].hasOwnProperty('produtoNome')
    )
      return

    if (this.dados_item.hasOwnProperty('produtoNome')) return

    this.showToast({
      variant: 'warning',
      message: this.$t('erro.carregar'),
    })
    return this.cancel()
  }  
}
</script>

<style lang="scss">
.buyer-data {
  text-align: left;
  form {
    display: flex;
    flex-wrap: wrap;

    > div,
    > div.form-inline {
      padding: var(--space);
      padding-top: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      h3 {
        width: 100%;
        margin: 0;
        margin-bottom: var(--space__0-5x);
      }
      @media (max-width: 576px) {
        padding: 0;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      > div {
        @media (max-width: 576px) {
          width: 48%;
          margin: 0;
        }
        @media (min-width: 576px) {
          margin-right: var(--space);
        }
        > div {
          margin-top: var(--space__0-75x);
        }
        label {
          font-weight: bold !important;
        }
        div.dass-input-text--input-wrapper {
          margin-top: 0;
        }
      }
    }

    .actions {
      > button:first-of-type {
        margin-right: var(--space);
      }
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;      
    }
  }
  #dass-button-tertiary-close-umbro {
    color: var(--theme-color) !important;    
  }
}
</style>
