import * as types from './mutation-types'
import { showError } from '../toasts/actions'
import { BRANDS } from '../../../helpers/constants/brands'

export const switchBrand = async ({ commit }, current_url) => {
  let brand = {
    name: 'err',
    id: -1,
    code: -1,
    theme_color: 'gold',
    class_name: 'theme',
  }

  if (current_url.includes('newbalance')) {
    brand = BRANDS.NEW_BALANCE
  }
  
  if (current_url.includes('localhost')) {
    brand = BRANDS.FILA
  }

  if (current_url.includes('umbro')) {
    brand = BRANDS.UMBRO
  }
  
  if (current_url.includes('fila')) {
    brand = BRANDS.FILA
  }

  if (brand.id === -1) {
    return showError('Erro ao definir a marca atual...')
  }
  commit(types.SET_BRAND, { brand })
}
